<template>
    <div class="sector">
        <SanackBar
            :activated="snackActivated"
            :success="snackSuccess"
            :message="snackMessage"
            @closeSnackBar="closeSnack()" />

        <v-alert
            color="#f0f0f0"
            icon="mdi-alert-circle-outline"
            border="left"
            v-if="!groups.length">
            <p>Você não possui grupo cadastrado!</p>

            <v-btn
                title="Cadastrar"
                color="primary"
                elevation="0"
                rounded
                large
                dense
                @click="goTo()">
                <span>Cadastrar</span>
            </v-btn>
        </v-alert>
        <div class="form" v-else>
            <div class="form-head">
                <h1 class="head-title">{{ titles.sector }}</h1>
            </div>

            <div class="form-fields">
                <div class="field-box field-name">
                    <label>Nome da campanha</label>

                    <v-text-field
                        v-model="campaign.name"
                        ref="name"
                        type="name"
                        filled
                        rounded
                        dense>
                    </v-text-field>
                </div>

                <div class="field-box">
                    <label>Selecione o grupo</label>

                    <v-select
                        v-model="campaign.groupId"
                        :items="groups"
                        chips
                        clearable
                        item-text="name"
                        item-value="id"
                        filled
                        rounded></v-select>
                </div>

                <div class="field-box field-date-hour">
                    <div class="field-date">
                        <v-menu
                            ref="menuDatePicker"
                            v-model="menuDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatedComputed"
                                    label="Data de expiração"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on" />
                            </template>

                            <v-date-picker
                                v-model="campaign.expirationDate"
                                @input="menuDatePicker = false" />
                        </v-menu>
                    </div>

                    <div class="field-hour">
                        <v-menu
                            ref="menu"
                            v-model="menuHourPicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="campaign.expirationTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="timeFormatedComputed"
                                    label="Hora da expiração"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>

                            <v-time-picker
                                v-if="menuHourPicker"
                                v-model="campaign.expirationTime"
                                full-width
                                @click:minute="$refs.menu.save(campaign.expirationTime)">
                            </v-time-picker>
                        </v-menu>
                    </div>
                </div>
                <div class="field-box field-submit">
                    <SubmitFormButton
                        :title="titles.submitButton"
                        :disabled="formSubmited"
                        @submitForm="submitForm()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import SubmitFormButton from '@/components/SubmitFormButton.vue';

    export default {
        name: 'FormCampaign',
        components: { SanackBar, SubmitFormButton },
        data() {
            return {
                isEditionMode: false,
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                formSubmited: false,
                groups: [],
                groupSelected: null,
                menuDatePicker: false,
                menuHourPicker: false,
                campaign: {
                    name: null,
                    groupId: null,
                    expiration: null,
                    expirationTemp: null,
                    expirationDate: null,
                    expirationTime: null
                },
                titles: {
                    sector: null,
                    submitButton: null
                }
            };
        },
        async mounted() {
            await this.serviceLoadingGroups();

            await this.checkIsEditionMode();

            this.loadingExpirationPicker();
        },
        watch: {
            'campaign.expirationDate'() {
                this.campaign.expiration =
                    this.campaign.expirationDate + 'T' + this.campaign.expirationTime;
            },
            'campaign.expirationTime'() {
                this.campaign.expiration =
                    this.campaign.expirationDate + 'T' + this.campaign.expirationTime;
            }
        },
        computed: {
            ...mapGetters({
                userStore: 'getDataUser',
                campaignStore: 'getDataCampaign'
            }),
            dateFormatedComputed() {
                return moment(this.campaign.expiration).format('DD/MM/YYYY');
            },
            timeFormatedComputed() {
                return moment(this.campaign.expiration).format('HH:mm');
            }
        },
        methods: {
            activeSnack(success, message) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = message;
            },
            closeSnack() {
                this.snackActivated = false;
            },
            goTo() {
                return this.$router.push({ name: 'groupFormCompany' });
            },
            async checkIsEditionMode() {
                if (this.campaignStore.isEditionMode) {
                    this.titles.sector = 'Editar campanha';
                    this.titles.submitButton = 'Atualizar';
                    this.isEditionMode = true;

                    await this.serviceLoadingCampaign();
                } else {
                    this.titles.sector = 'Criar campanha';
                    this.titles.submitButton = 'Criar';
                }
            },
            loadingExpirationPicker() {
                this.loadingExpirationDatePicker();

                this.loadingExpirationTimePicker();
            },
            loadingExpirationDatePicker() {
                const dateToCreate = new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 10);

                const dateToUpdate = this.campaign.expirationTemp?.substr(0, 10);

                this.campaign.expirationDate = !this.isEditionMode
                    ? dateToCreate
                    : dateToUpdate;
            },
            loadingExpirationTimePicker() {
                const timeToCreate = new Date(
                    Date.now() - new Date().getTimezoneOffset() * 40000
                )
                    .toISOString()
                    .toString()
                    .substr(11, 5);

                const timeToUpdate = this.campaign.expirationTemp?.substr(11, 5);

                this.campaign.expirationTime = !this.isEditionMode
                    ? timeToCreate
                    : timeToUpdate;
            },
            dataGroupTransform(data) {
                const groups = [];

                data.forEach((e) => {
                    groups.push({
                        id: e.id,
                        name: e.name
                    });
                });

                return groups;
            },
            goToAfterSubmitForm() {
                this.$router.push({ name: 'campaignsCompany' });
            },
            dataParse(data) {
                const { name, groupId, expirationDate, expirationTime } = data;

                const dateConvertedToUtc = moment(
                    `${expirationDate}T${expirationTime}`,
                    'YYYY-MM-DDTHH:mm'
                ).utc();

                return {
                    name,
                    group_id: groupId,
                    expiration: dateConvertedToUtc
                };
            },
            validateSubmitForm() {
                const snack = (message) => this.activeSnack(false, message);

                const validateCampaignName = (name) => {
                    if (!name) {
                        snack('Preencha o campo nome da campanha!');
                        return true;
                    }

                    if (name.length < 6) {
                        snack('O nome da campanha deve conter no mínimo 6 caracteres!');
                        return true;
                    }

                    if (name.length > 128) {
                        snack('O nome da campanha deve conter até 128 caracteres!');
                        return true;
                    }

                    return false;
                };

                const validateCampaignGroup = (campaginGroup) => {
                    if (!campaginGroup) {
                        snack('Selecione um grupo para campanha!');
                        return true;
                    }

                    return false;
                };

                const errors = [
                    validateCampaignGroup(this.campaign.groupId),
                    validateCampaignName(this.campaign.name)
                ];

                return !errors.some((e) => e);
            },
            buildCampaign(data) {
                Object.keys(this.campaign).forEach((key) => {
                    this.campaign[key] = data[key];
                });

                const timeLocal = moment(data.expiration)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss');

                this.campaign.expirationTemp = timeLocal;
            },
            async serviceLoadingCampaign() {
                this.$store.commit('setGlobalLoading', true);

                const campaignId = this.campaignStore.id;

                await this.$service.http['company']
                    .getCampaignById(campaignId)
                    .then((result) => {
                        const record = result.data.doc;

                        this.buildCampaign(record);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            serviceLoadingGroups() {
                this.$store.commit('setGlobalLoading', true);

                const currentUserId = this.userStore.id;

                this.$service.http['company']
                    .getListGroups(currentUserId)
                    .then((result) => {
                        const records = result.data.docs;

                        this.groups = this.dataGroupTransform(records);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceUpdateCampaign() {
                const dataParsed = this.dataParse(this.campaign);

                const campaignId = this.campaignStore.id;

                await this.$service.http['company']
                    .updateCampaign(campaignId, dataParsed)
                    .then((_) => {
                        this.activeSnack(true, 'Campanha atualizada!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 2000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    })
                    .finally((_) => {
                        this.formSubmited = false;

                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceCreateCampaign() {
                const dataParsed = this.dataParse(this.campaign);

                await this.$service.http['company']
                    .createCampaign(dataParsed)
                    .then((_) => {
                        this.activeSnack(true, 'Campanha cadastrada!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 2000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    })
                    .finally((_) => {
                        this.formSubmited = false;

                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async submitForm() {
                if (!this.validateSubmitForm()) return;

                this.$store.commit('setGlobalLoading', true);

                this.formSubmited = true;

                if (this.campaignStore.isEditionMode) {
                    await this.serviceUpdateCampaign();
                } else {
                    await this.serviceCreateCampaign();
                }

                this.formSubmited = false;

                this.$store.commit('setGlobalLoading', false);
            }
        }
    };
</script>

<style scoped>
    .sector {
        width: 350px;
    }

    .sector-head-title {
        font-size: 26px;
    }

    .field-name {
        margin-top: 20px;
    }

    .field-submit {
        margin-top: 10px;
    }

    .field-date-hour {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .field-date-hour .field-date,
    .field-date-hour .field-hour {
        width: 48%;
    }
</style>
